<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ประวัติการเติมน้ำมัน
      </v-toolbar-title>
      <v-spacer/>
      <v-btn @click="$router.push({path:'/oil-tracking/vehicle'})" depressed color="primary" class="mr-2">
        ดูข้อมูลตามรถ
      </v-btn>
      <v-btn
          v-if="$store.state.authUser.user_permissions.includes('add_oiltransaction')"
          @click="$router.push({path:'/oil-tracking/create'})" depressed color="success">
        <v-icon left>mdi-plus</v-icon>
        เพิ่มรายการเติมน้ำมันใหม่
      </v-btn>
      <v-btn icon @click="showFilter = !showFilter">
        <v-icon>
          mdi-filter
        </v-icon>
      </v-btn>
    </v-toolbar>

    <div class="mr-4 box box-2" style="min-height: 100%;">
      <splitpanes style="height: 100%">
        <pane :size="showFilter ? 80 : 100" class="pl-4 pb-4">
          <v-card
              style="height: 100%; overflow: auto"
              class=" rounded-lg elevation-2"
              outlined
              tile
          >
            <v-data-table
                :header-props="{ sortIcon: null }"
                :headers="headers"
                :items="oilTransactions"
                :search="search"
            >

              <template v-slot:item.price="{item}">
                <p class="text-right mb-0">{{ $currency(item.price).format() }}</p>
              </template>

              <template v-slot:item.status="{ item }">
                <v-btn
                    :color="colorStatus(item)"
                    outlined
                    raised
                    small
                    block
                >{{ item.status === 'IN' ? 'เติมเงิน' : 'เติมน้ำมัน' }}
                </v-btn>
              </template>

              <template v-slot:item.action="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-information</v-icon>
                    </v-btn>
                  </template>
                  <span>เปิดโดย : {{ item.responsible_user.first_name }} {{ item.responsible_user.last_name }}</span>
                </v-tooltip>
              </template>

              <template slot="body.append">
                <tr style="background-color: #ecffea">
                  <td>
                    รวมเติมเงินเข้า
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right"><b>{{ $currency(total_IN).format() }}</b></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr style="background-color: #eaf8ff">
                  <td>
                    รวมเติมน้ำมัน
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right"><b>{{ $currency(total_OUT).format() }}</b></td>
                  <td></td>
                  <td></td>
                </tr>
              </template>

            </v-data-table>
          </v-card>
        </pane>
        <pane :size="showFilter ? 20 : 0" class="pb-4">
          <FilterCard @search="getOliTransaction" date vehicle oil-status price-min-max :backwards="3"/>
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import FilterCard from "@/components/FilterCard";

export default {
  components: {FilterCard, Splitpanes, Pane},
  name: "OilTracker",
  data() {
    return {
      showFilter: true,
      oilTransactions: [],
      optionSearch: '',
      search: '',
      headers: [
        {
          text: 'วันที่',
          value: 'date',
          align: 'center',
          width: '120'
        },
        {
          text: 'ทะเบียน',
          value: 'vehicle.licence',
          align: 'center',
        },
        {
          text: 'ชื่อรถ',
          value: 'vehicle.name',
          align: 'center',
        },
        {
          text: 'จำนวนลิตร',
          value: 'liter',
          align: 'center',
        },
        {
          text: 'ราคาต่อลิตร',
          value: 'per_liter',
          align: 'center',
        },
        {
          text: 'ราคารวม',
          value: 'price',
          align: 'center',
        },
        {
          text: 'รูปแบบ',
          value: 'status',
          align: 'center',
          width: '120'
        },
        {
          text: '',
          value: 'action',
          align: 'center',
          width: '60'
        }
      ],
      total_IN: 0,
      total_OUT: 0
    }
  },
  created() {
    // this.getOliTransaction()
  },
  methods: {
    getOliTransaction(query) {
      this.queryString = query
      axios.get('/api/tracks/oil/' + query).then(res => {
        this.total_IN = 0
        this.total_OUT = 0
        this.oilTransactions = res.data.data
        this.oilTransactions.forEach((item) => {
          if (item.status === 'IN') {
            this.total_IN += item.price
          } else {
            this.total_OUT += item.price
          }
        }, 0)
      })
    },
    colorStatus(item) {
      if (item.status === 'OUT') {
        return 'primary'
      } else {
        return 'success'
      }
    }
  }
}
</script>

<style scoped>

</style>
